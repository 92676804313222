.main_bg {
  background-color: white;
  width: auto;
  height: auto;
  text-align: center;
  padding: 4px;
  padding: 10px 25px;
}
.today-date {
  float: right;
  // align-self: flex-end;
  margin-right: 10px;
}
.institute-logo {
  height: 150px;
  width: 150px;
  float: left;
  margin-left: 10px;
}
.header-container {
  border: 1px solid #172b4d;
  margin-right: 1px;
}
.header {
  display: flex;
}
.content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
h1 {
  font-size: 43px;
  letter-spacing: 0.1em;
}
.heading {
  font-size: 25px;
  text-align: center;
}
.permanate-title-container {
  background-color: white;
  /* border: 0.3px solid grey; */
}

.container {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  .container {
    overflow-x: hidden;
  }
}

.title_container {
  color: white;
  background-color: var(--normal-blue);
  // width: 95vw;
  height: 40px;
  text-align: left;
  font-size: 25px;
}

h5 {
  margin-left: 10px;
  margin-top: 3px;
}

.feild_container {
  display: grid;
  color: black;
  justify-content: space-between;
  background-color: white;
  padding: 20px;
  // width: 95vw;
  border: 0.1px solid #ccc !important;
  border-style: solid;
  border-width: 3px;
}

.feild_container .ant-form-item {
  margin: 5px 0 0 0;
}

.feild_container1 {
  display: grid;
  color: black;
  justify-content: space-between;
  padding: 20px;
  width: 854px;
  margin-left: 100px;
}

.datepicker-field {
  width: 350px !important;
}
.dashboard-grid {
  grid-template-columns: repeat(2, 1fr);
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 15px;
}

.btn {
  display: flex;
  justify-content: center;
  margin-left: 990px;
}

.btn2 {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.select-input-field {
  width: 350px;
}

.input-field {
  width: 350px;
}
.siblings-main-container {
  display: flex;
  flex-direction: column;
}
.sibling-details {
  display: flex;
  flex-direction: row;

  gap: 5px;
  .siling-input-field {
    width: 250px;
  }
}
.form_save-btn {
  background-color: var(--normal-blue);
  color: var(--white-font);
  margin-top: 10px;
}

.form_save-btn:hover {
  background-color: var(--normal-blue) !important;
  color: var(--white-font) !important;
  margin-top: 10px;
}

.textarea-field {
  width: 350px;
  height: 100px;
  margin: 5px;
}
.ant-select-single {
  font-size: 14px;
  height: 32px;
  text-align: start;
}

@media only screen and (max-width: 1024px) {
  .grid-container {
    display: grid;
    grid-template-columns: auto auto;
  }

  .select-input-field {
    width: 370px;
  }

  .input-field {
    width: 350px;
  }

  .textarea-field {
    width: 370px;
    height: 100px;
  }
}

@media (max-width: 771px) {
  .container {
    overflow-x: hidden;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto auto;
  }

  .select-input-field {
    width: 270px !important;
  }

  .input-field {
    width: 270px;
  }

  .textarea-field {
    width: 200px;
    height: 50px;
  }

  .datepicker-field {
    width: 270px;
  }

  .title_container {
    margin-top: 20px;
  }
}

@media (max-width: 524px) {
  .container {
    margin-top: 30px;
    overflow-x: hidden;
  }

  .title_container {
    margin-top: 20px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    align-items: center;
  }

  .select-input-field {
    width: 350px !important;
  }

  .input-field {
    width: 350px;
  }

  .textarea-field {
    margin-top: 10px;
    width: 250px;
    height: 50px;
  }

  .datepicker-field {
    width: 350px !important;
  }
}
.form-submit {
  color: #fff;
  background-color: var(--dark-blue);
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  float: center;
  margin-top: 10px;
}
.form-submit:hover {
  color: #fff !important;
  background-color: var(--dark-blue);
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  float: center;
  margin-top: 10px;
}
.form-submit:focus {
  color: #fff !important;
  background-color: var(--dark-blue);
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  float: center;
  margin-top: 10px;
}
