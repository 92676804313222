@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: 500;
}

body {
  overflow-x: hidden !important;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
}
:root {
  --yellow: #ebff00;
  --white-font: #ffffff;
  --grey: #e5e5e5;
  --dark-blue: #172b4d;
  // --dark-blue: #0a378f;
  --dark-yellow: #ffc700;
  --normal-blue: #172b4d;
  --pitch-black: #000000;
  --lemon-green: #70b518;
  --grey-btn: #d9d9d9;
  --violet-blue: #172b4d;
  --sky-btn-bg: rgba(0, 240, 255, 0.35);
  --blood-red: #ff0000;
  --dark-sky: #172b4d;
}
.main {
  padding-top: 20px;
}
