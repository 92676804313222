.sucess-container{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 200px;
    .thank-you-image{
        height: 300px;
        width: 70%;
        margin-bottom: 20px;
    }
    h1{
        text-align: center;
        font-size: 30px;
    }
}