.not-found-page-container {
  text-align: center;
  margin: 0 auto;
}

.not-found-image {
  width: 70%;
  max-width: 500px;
  height: auto;
}

.not-found-heading {
  font-size: 24px;
}

.not-found-subheading {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .not-found-image {
    max-width: 400px;
  }

  .not-found-heading {
    font-size: 20px;
  }

  .not-found-subheading {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .not-found-image {
    max-width: 300px;
  }

  .not-found-heading {
    font-size: 18px;
  }

  .not-found-subheading {
    font-size: 14px;
  }
}
