.float-label {
  position: relative;
}

.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 5px;
  transition: 0.2s ease all;
}

.as-placeholder {
  color: gray;
}

.as-label {
  top: -8px;
  font-size: 12px !important;
  background: white;
  padding: 0 4px;
  margin-left: -4px;
}

@media only screen and (max-width: 480px) {
  .as-label {
    top: -20px;
    font-size: 12px !important;
    background: white;
    padding: 0 4px;
    margin-left: -4px;
  }
}


/* Hide the spin buttons for all number inputs in WebKit browsers (Chrome, Safari) */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the spin buttons for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
